<template>
  <intervenants :type="'exposant'">
    Aucun exposants n'est encore annoncés pour cette édition.
  </intervenants>
</template>

<script>
import Intervenants from "./Intervenant/Intervenants";

export default {
  name: "Exposants",
  components: {Intervenants},
};
</script>

<style lang="scss" scoped>
</style>
