<template>
  <v-container class="fill-height pa-0 ma-0" :fluid="$vuetify.breakpoint.mobile" >
    <v-alert type="info" v-if="!loading &&!intervenants.length" class="mt-10">
      <slot></slot>
    </v-alert>
    <v-row no-gutters justify="center" align="center" class="fill-height">
      <v-col
          v-for="intervenant in intervenants"
          :key="intervenant.id"
          cols="12" sm="5" md="2"
          class="ma-1"
      >
        <v-card>
          <router-link :to="{ name: 'Single-intervenant', params:{intervenantId:intervenant.id} }"
                       style="text-decoration: none">
            <v-card-title class="ludi-blue not-more-than-3-lines text-center white--text text-center" v-text="intervenant.nom"/>
            <v-img :src="getFullURL(intervenant.mediaObjectImage)" height="200px" max-width="100vw"/>
          </router-link>
          <v-card-actions style="min-height: 50px">
            <v-spacer></v-spacer>
            <v-btn icon v-if="intervenant.siteWeb" target="_blank" :href="intervenant.siteWeb">
              <v-icon>mdi-link-variant</v-icon>
            </v-btn>
            <v-btn icon v-if="intervenant.facebook" target="_blank" :href="intervenant.facebook">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MediaObjectMixin from "../../../mixins/MediaObjectMixin";
import IntervenantService from "../../../services/intervenant.service";

export default {
  name: "Intervenants",
  props:["type"],
  mixins: [MediaObjectMixin],
  data: () => ({
    intervenants: [],
    loading: true,
  }),
  created() {
    this.loadIntervenants();
  },
  methods: {
    loadIntervenants() {
      let pagination = {
        typeSlug: this.type,
        evenementId : this.$announcedEvent.id
      }
      IntervenantService.getIntervenants(pagination).then(intervenants => {
        this.intervenants = intervenants.data['hydra:member']
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }

  .paraph {
    font-family: $font;
  }
}
</style>